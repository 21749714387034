//
// Base
//

// Body
body {
  background: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-fixed & {
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Content Head
    .header-fixed.subheader-fixed.subheader-enabled & {
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Aside
    .aside-fixed & {
      padding-left: get($aside-config, base, width);
    }

    // Minimize Aside
    .aside-fixed.aside-minimize & {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Container
  .container,
  .container-fluid {
    padding: 0 get($page-padding, desktop);
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }
  }

  // Container
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($page-padding, tablet-and-mobile);
  }
}

.hours-form {
  .MuiFormControlLabel-root {
    display: block;
  }

  tbody > tr:first-child td {
    border-top: none;
  }

  .table.table-vertical-center th,
  .table.table-vertical-center td {
    &.vertical-top {
      vertical-align: top;
    }
    &.name {
      padding-top: 15px;
    }
    &.closed {
      padding-top: 5px;
      min-width: 140px;
    }
  }
}

.rbc-today,
.rbc-day-slot {
  background-color: unset;

  .closed-slot {
    .rbc-time-slot {
      background-color: #f3f6f9;
    }
  }
}

.rbc-time-gutter {
  svg {
    display: none;
  }
}

.rbc-day-slot {
  z-index: 1;

  .rbc-timeslot-group {
    position: relative;

    .rbc-time-slot {
      z-index: 10;
    }

    .active {
      .rbc-time-slot {
        background-color: #3699ff;
      }
    }

    div:nth-of-type(1) {
      .custom-time-slot {
        position: absolute;
        left: 0;
        top: 0;
        bottom: unset;
        width: 100%;
        height: 50%;
      }
    }

    div:nth-of-type(2) {
      .custom-time-slot {
        position: absolute;
        left: 0;
        bottom: 0;
        top: unset;
        width: 100%;
        height: 50%;
      }
    }

    .custom-time-slot {
      cursor: pointer;

      &:hover {
        background-color: #3699ff;
      }
    }

    .blocked-slot {
      background-color: lightgray;
      border: none;
      &:hover {
        background-color: darkgray;
      }
    }
  }

  .custom-time-slot__icon {
    z-index: 10;
    height: 1.5rem;
    width: 1.5rem;
    color: #fff;
    border-right: 1px solid #fff;

    &.hidden {
      opacity: 0;
    }
  }

  .rbc-event {
    z-index: 11;

    &__blocked {
      padding: 0;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      &.week {
        flex-wrap: nowrap;
      }

      .rbc-event-content {
        margin-right: 0.5rem;
        border-right: 1px solid rgb(63, 66, 84);
        width: unset;
        flex: none;

        &__unblock-icon {
          width: 1.5rem;
          height: 1.5rem;
          padding: 2px;
        }
      }
    }
  }
}
